html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 26px;
    background: #ffffff;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    min-width: 320px;
    margin: 0px;
}

:root{
    /* Colors */
    --content-color: #6F6C70;
    --intro-color: #ffffff;
    --h1-color: #ffffff;
    --h1-thin-color: #ffffff;
    --h2-color: #44B9BB;
    --h3-color: #595959;
    --site-header-color: #4EC2C4;
    --content-title-color: #4C5359;

    /* Sizes */
    --h1-size: 50px;
    --h1-thin-size: 30px;
    --h2-size: 33px;
    --h3-size: 22px;
    --h2-size-mobile: 25px;
    --content-size: 15px;
    --intro-size: 50px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.scroll-to-link * {
    pointer-events: none;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

h1, h1 .thin, h2, h3, p, p+p {
    font: Poppins;
    font-style: normal;
    text-align: center;
}

h1{
    text-transform: uppercase;
    color: var(--h1-solor);
    font-size: var(--h1-size);
    line-height: 55px;
    font-weight: 700;
    font-size: 48px;
    margin: 0 auto 20px;
}

h1 .thin {
    text-transform: none;
    display: block;
    color: var(--h1-thin-color);
    font-size: var(--h1-thin-size);
    font-weight: 300;
    line-height: 40px;
    text-align: center;
}

h2 {
    font-size: var(--h2-size);
    color: var(--h2-color);
    font-weight: bold;
    line-height: 30px;
    margin-top: 80px;
    margin-bottom: 40px;
}

h3 {
    color: var(--h3-color);
    font-size: var(--h3-size);
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-top: 45px;
}

p {
    font-size: var(--text-size);
    line-height: 26px;
    font-weight: 500;
}

p+p {
    color: var(--content-color);
}

ul {
    list-style: none;
}

ol {
    color: var(--h2-color);
    font-weight: 900;
    margin-left: 115px;
}

ol li p {
    text-align: left;
    margin-left: -10px;
    margin-right: 100px;
    padding-left: 15px;
    margin-bottom: 26px;
    font-weight: 600;
}

ol li p strong{
    font-weight: 800;
    color: #474747;
}

a {
    text-decoration: none;
}

.site-logo {
    width: 103px;
    height: 82px;
    background: url("../image/logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
    margin-top: 60px;
}

.logo-wrapper {
    padding-top: 0px;
    padding-bottom: 22px;
    display: flex;
    justify-content: center;
    height: 161px;
    position: relative;
    z-index: 1;
}

.site-header {
    color: #fff;
    background-color: var(--site-header-color);
    position: relative;
    overflow: hidden;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    position: relative;
    z-index: 1;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #090909;
    margin-bottom: 15px;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
    text-align: left;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


.site-header-inner {
    position: relative;
    padding: 45px 60px 105px;
    text-align: center;
}

.header-image {
    position: absolute;
    right: 15px;
    bottom: 0;
}


.image-wrapper{
    position: absolute;
    background-image: url("../image/hero-image.webp");
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.site-header:after {
    background: #A4B2E1;
    bottom: 0;
    content: "";
    filter: blur(300px);
    height: 70%;
    margin-bottom: auto;
    margin-top: auto;
    opacity: .8;
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
}

.intro {
    width: 487px;
    margin: 0 auto;
   
    margin-bottom: 80px;
}

.intro-expand {
    display: block;
}

.cta-btns li:first-of-type span:before{
    background-image: url("../image/tool.png");
    left: -25px;
    top: 2px;
}

.cta-btns li:nth-of-type(2) span:before{
    cursor: pointer;
    background: url("../image/features.png") no-repeat 0 0/contain;
    display: inline-block;
    font-size: 0px;
    width: 20px;
    height: 19px;
    vertical-align: middle;
    margin-top: 0;
}

.cta-btns li:nth-of-type(3) span:before{
    cursor: pointer;
    background: url("../image/what.png") no-repeat 0 0/contain;
    display: inline-block;
    font-size: 0px;
    width: 17px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: 3px;
}

.cta-btns li:nth-of-type(4) span:before{
    cursor: pointer;
    background: url("../image/sell.png") no-repeat 0 0/contain;
    display: inline-block;
    font-size: 0px;
    width: 22px;
    height: 22px;
    vertical-align: middle;
    margin-left: -2px;
    margin-top: 1px;
}

.cta-btns li a:hover span::before{
    filter: invert(32%) sepia(9%) saturate(3547%) hue-rotate(132deg) brightness(94%) contrast(83%);
}

.cta-btns span::before{
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    left: -25px;
    top: 2px;
    transition: filter 0.2s ease-in-out;
}

.boxes {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 36px;
}

.box {
    flex: 0 0 31%;
    text-align: center;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 18px rgba(0,0,0,0.08);
    border-collapse: collapse;
    display: flex;
    padding-bottom: 25px;
}

.box-inner {
    flex: 0 0 100%;
    background: rgb(255, 255, 255);
    border-radius: 20px;
}

.box-icon-1 {
    background: url("../image/convert.png") no-repeat 0 0/contain;
    display: inline-block;
    width: 159px;
    height: 159px;
    margin-top: 60px;
}

.box-icon-2 {
    background: url("../image/blockchain.png") no-repeat 0 0/contain;
    display: inline-block;
    width: 159px;
    height: 159px;
    margin-top: 60px;
}

.box-icon-3 {
    background: url("../image/process.png") no-repeat 0 0/contain;
    display: inline-block;
    width: 159px;
    height: 159px;
    margin-top: 60px;
}

.box-text {
    font-size: 14px;
    line-height: 26px;
    color: #6F6C70;
    margin-left: 30px;
    margin-right: 30px;
}

.box-headline {
    font-size: var(--h3-size);
    line-height: 26px;
    color: var(--h3-color);
    margin-left: 30px;
    margin-right: 30px;  
}

.nav{
    position: relative;
    z-index: 2;
    padding: 15px 44px;
}

.nav-wrapper{
    width: 915px;
    position: relative;
    font-size: 17px;
    font-weight: 510;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    margin: 0 auto 0;
    color:#FFFFFF;
}

nav ul li a:hover{
    color: #00a4d6;
    transition:color .2s ease-in-out;
}

.nav-wrapper-bg{
    background-color: #fff;
    border-radius: 69px;
    opacity: 0.15;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.btn-lis a{
    color: white;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center left;
    position: relative;
}

span{
    position: relative;
}

.span{
    position: relative;
    margin-left: 30px;
}

p:not(:last-child){
    margin-bottom: 25px;
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
}

.nav-wrapper ul {
    display: flex;
    justify-content: space-around;
}

.nav-wrapper li {
    margin-right: 31px;
}

/* H2-HEADERS */
 .h2-header{
     margin-top: 79px;
     color: var(--h2-color);
     font-size: var(--h2-size);
     font-weight: 700;
     text-align: center;
}

 .articles{
     align-items: center;
     text-align: center;
}

.articles p{
    color: var(--content-color);
    line-height: 26px;
    font-size: 15px;
}

.navigation-grid li{
    text-align: center;
    padding: 25px 12px 0;
    border-radius: 20px 20px 0 0 ;
}

.content{
    text-align: center;
    font-size: 14px;
    color: var(--content-color);
}

.navigation-content{
    padding: 30px 20px;
    text-align: center;
    line-height: 30px;
    border-radius: 0 0 22px 22px;
}

.box-horizontal-content {
    max-width: 1287px;
    box-shadow: 0px 0px 25px 0px #00000014;
    border-radius: 20px;
    padding: 34px 40px;
    margin-top: 43px;
}

.box-horizontal-content-description {
    font-size: 14px;
    color: var(--content-color);
    line-height: 26px;
    margin-top: 20px;
    text-align: left;
    padding-top: 1px;
}

.box-horizontal-headers {
    font-size: 22px;
    color: var(--content-title-color);
    text-transform: uppercase;
    margin-left: 23px;
    position: relative;
}

.box-horizontal-h3::before {
    content: "";
    width: 45px;
    height: 45px;
    background-color: #D7F8F9;
    position: absolute;
    border-radius: 50%;
    top: -8px;
    z-index: -1;
    left: 0;
}

.box-horizontal-h3 {
    text-align: left;
    margin-top: 10px;
    position: relative;
    z-index: 1;
    text-indent: 20px;
}

.create-nft{
    text-align: center;
}

.create-nft-content{
    font-size: 15px;
    color: var(--content-color);
    line-height: 26px;
    text-align: center;
}

.our-team-wrapper{
    background-color: #F5F4F7;
}

.best-marketplace{
    padding-bottom: 80px;
}

.wrapper-inner{
    padding-top: 20px;
    padding-bottom: 105px;
    text-align: center;
}

.wrapper-inner h2{
    font-size: var(--h2-size);
    color: var(--h2-color);
    text-align: center;
}

.faq-wrapper-outer{
    background-color: #4EC2C4;
    padding-top: 30px;
    overflow: hidden;
    position: relative;
}
.bg-gradient-inner {
    background: #A4B2E1;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(300px);
    height: 100%;
    opacity: .8;
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
}
.faq-wrapper{
    position: relative;
    z-index: 2;
}

.faq-section-wrapper{
    padding-bottom: 90px;
}

.faq-heading {
    margin-bottom: 38px!important;
    padding-top: 68px!important
}

.faq.expanded+.faq {
    padding-top: -20px;
}

.faq-title-h2 {
    color: snow;
    font-size: 33px;
    font-weight: 600;
}

.faq-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.faq-title h3 {
    color: var(--h1-color);
    font-size: 22px;
    line-height: 98px;
    pointer-events: none;
    margin-top: 25px;
    margin-bottom: 25px;
    text-transform: none;
}

.faq-btn {
    align-items: center;
    border: 0;
    border-radius: 25px;
    display: flex;
    font-size: 42px;
    height: 49px;
    justify-content: center;
    margin-right: 20px;
    width: 49px;
}

.faq-btn:hover,.faq-title:hover {
    cursor: pointer
}

.faq {
    padding-left: 40px;
    transition: background-color .2s linear,font-weight .2s linear,border-radius .2s linear;
    position: relative;
    margin-bottom: 25px;
}

.faq-background-outer{
    background-color: white;
    opacity: 0.1;
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.faq-content{
    position: relative;
    z-index: 2;
}

.faq .faq-body {
    color: white;
    font-size: 15px;
    line-height: 26px;
    padding-bottom: 44px;
    border-top: 1px solid #a3d9da;
    padding-top: 25px;
    width: 95%;
}

.faq.collapsed .faq-body {
    display: none;
}

.faq-body a {
    font-weight: bold;
    color: white;
    text-decoration: underline;
}

.faq-body .ul-bullet li{
    margin-left: 0;
}

.faq-body .ul-bullet li p{
    font-weight: 400;
}

.faq-btn span {
    pointer-events: none;
}

.faq.collapsed .faq-btn span {
    color: white;
}

.faq.expanded .faq-btn span {
    color: white;
}

.faq.collapsed .faq-btn span.plus,.faq.expanded .faq-btn span.minus {
    display: block;
}

.faq.collapsed .faq-btn span.minus,.faq.expanded .faq-btn span.plus {
    display: none;
}

.faq.expanded .faq-title h3 {
    font-weight: 700;
}

.text-center {
    text-align: center;
    color: white;
}

.text-center p{
    padding-bottom: 50px;
    color: #fff;
}

.faq-title h3{
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.faq-body p {
    color: white;
    text-align: left;
}

.contact-us-outer {
    position: relative;
}

.contact-us-inner {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 10px 20px 100px;
}

.contact-us-inner h2 {
    font-size: var(--h2-size);
    color: white;
    font-weight: 700;  
}

.contact-us-inner p {
    color: white;
    font-size: 15px;
    margin-bottom: 50px;
}

.contact-btn {
    background-color: #ffffff;
    font-size: 20px;
    padding: 20px 40px;
    border-radius: 69px;
    color: #68CBCD;
    position: relative;
    font-weight: 600;
    margin-top: 60px;
}

.contact-btn:hover {
    background-color: #e5fdfd;
}

/*
.contact-btn:hover:before {
    background: #000000;
    border-radius: 22px;
    bottom: 0;
    color: #fff;
    content: "Services under development";
    height: inherit;
    justify-content: center;
    left: 0;
    opacity: .8;
    position: absolute;
    text-align: center;
    transform: translateY(-80px);
    width: inherit;
    font-size: 15px;
    font-weight: 300;
    padding: 5px 15px;
    margin-left: 3px;
}

.contact-btn:hover:after {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 8px solid #000000;
    clear: both;
    content: "";
    height: 0;
    left: 46%;
    opacity: .8;
    position: absolute;
    top: 4px;
    transform: translateY(-16px);
    width: 0;
    margin-left: 3px;
}
*/

h1 .uppercase {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 48px;
    /* line-height: 61px; */
}

.header-lower {
    margin-bottom: 35px;
}

.header-lower-text {
    font-weight: 400;
    font-size: 30px;
    /* line-height: 38px; */
}

footer, .contact-us-outer {
    background-color: #72c6d0;
}

footer .container {
    text-align: center;
    padding: 20px;
    border-top: 1px solid #abe7ee;
}

footer .copyright {
    color: #828C98;
    font-size: 14px;
    color: white;
    font-weight: 400;
}

.m-visible {
    display: none;
}

.img-left {
    float: left;
    margin-right: 23px;
    padding-top: 10px;
}

.m-hidden {
    display: initial;
}

/* RESPONSIVE */
@media only screen and (max-width: 767px) {

    header, 
    article,
    footer {
        padding: 0px 22px;
    }
    article {
        padding: 0;
    }

    .intro {
        width: auto;
        margin-bottom: 30px;
    }

    .boxes {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .box {
       margin-bottom: 30px;
    }

    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 5px;
    }

    h2 {
        font-size: 25px;
        line-height: 32px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .h2-header {
        font-size: 25px;
        margin-top: 45px;
    }
    
    p {
        font-size: 15px;
    }

    p+p {
    font-size: 15px;
    }
    .navigation-articles-mobile {
        display: none;
    }

    ol {
        color: var(--h2-color);
        margin-left: 60px;
    }
    
    ol li p {
        margin-right: 25px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    
    .m-hidden {
        display: none;
    }

    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }
    .ul-bullet{
        padding: 20px;
    }
    
    .header-image {
        display: none;
    }

    .site-header-inner {
        padding: 26px 20px 50px;
        position: center;

    }

    .nav-wrapper p {
        margin-bottom: 20px;
    }

    .nav-wrapper ul {
        display: block;
        font-size: 15px;
        margin: -20px;
    }

    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }

    .nav-wrapper li {
        width: 100%;
        margin-right: 31px;
    }

    footer .container {
        flex-direction: column;
        padding-bottom: 24px;
    }

    footer .logo-wrapper {
        padding-bottom: 8px;
    }

    .site-logo {
        margin-top: 52px;
    }
    .header-lower{
        
        margin-bottom: 25px;
    }

    li{
        position: relative;
    }

    h1 .uppercase{
        font-size: 30px;
        width: 100%;
    }

    .nav-wrapper{
        width: 100%;
        margin: 0 auto 0;
    }

    .transparent-bg{
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.15;
        border-radius: 69px;
    }

    .draw-software-li a{
        z-index: 10;
        background-position: 33px
    }

    .feature-li a{
        z-index: 10;
        background-position: 80px;
    }

    .btn-lis a{
        justify-content: center;
        padding: 20px;
        position: relative;
    }

    .cta-btns li:first-of-type a:before{
        left: 143px;
        top: 23px;
    }

    .cta-btns li:nth-of-type(2) a:before{
        left: -25px;
        top: 2px; 
    }

    .cta-btns li:nth-of-type(3) a:before{
        left: -25px;
        top: 6px; 
    }

    .cta-btns li:nth-of-type(4) a:before{
        left: -25px;
        top: 2px;
    }

    .cta-btns{
        padding:0;
    }
    
    .navigation-articles-desktop{
        display: none;
    }

    .navigation-articles-mobile{
        display: inherit;
    }

    .card-content{
        text-align: center;
        border-radius: 20px;
        padding: 40px 33px;
        box-shadow: 0px 0px 25px 0px #00000014;
        margin-top: 40px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .card-content h3{
        color: #595959;
    }

    .card-content p{
        color: #6F6C70;
    }

    .more{
        color: #6F6C70;
        font-size: 15px;
        display: none;
        transition: all 3s ease-in;
    }

    .text.show-more .more{
        display: block;
        
    }

    .text.show-more .dots{
        display: none;
    }

    .show-more-btn{
        transform: rotate(180deg);
        transition: all 0.3s ease-in;
    }

    .box-horizontal-content{
        font-size: 14px;
        color: var(--content-color);
        line-height: 26px;
        text-align: left;
        margin-top: 0;
    }

    .box-horizontal-content-description{
        color: #6F6C70;
        text-align: center;
    }

    .create-nft{
        padding-left: 20px;
        padding-right: 20px;
    }

    .best-marketplace{
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
    }

    .our-team-wrapper{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: -10px;
    }
    .articles{
        padding: 0 20px;
    }

    .click-for-more{
        background: url("../image/more.png") no-repeat;
        background-position: center;
        height: 5.5em;
        width: 100%;
    }

    .faq-wrapper-outer {
        padding-top: 15px;
        font-size: var(--h2-size);
    }

    .faq-wrapper{
        padding-bottom: 30px;
        margin: 20px;
    }
    .faq-section-wrapper{
        padding-bottom: 0px;
    }

    .wrapper-inner {
        font-size: 25px;
        padding-bottom: 50px;
    }

    .img-left {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        float: none;
    }

    .img-left img{
        max-width: 100%;
    }

    .wrapper-inner h2 {
        font-size: var(--h2-size-mobile);
    }

    .faq-title-h2 {
        font-size: var(--h2-size-mobile);
    }

    .faq-title h3 {
        font-size: 16px;
        line-height: 23px;
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: left;
    }
    .faq-body ul,  .faq-body .ul-bullet {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    ul {
        margin: 20px;
    }

    .container h2{
        font-size: 22px;
    }

    .faq-content{
        margin-left: -20px;
    }

    ol {
        color: var(--h2-color);
        margin-left: 20px;
    }
    
    ol li p {
        margin-right: 0;
    }
    .nav-wrapper-bg{
        display: none;
    }
    .box-horizontal-h3{
        text-indent: 0;
        margin-left: 25%;
    }
    .box-horizontal-h3::before{
        top: 0;
        left: -30%;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
}

/* TABLET VERSION */
@media only screen and (max-width: 1100px) and (min-width: 768px){

    .nav-wrapper ul {
        display: block;
    }

    .btn-lis a{
        justify-content: center;
        padding: 20px;
    }

    .img-left{
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        float: none;
    }

    .nav-wrapper{
        width: 100%;
        margin-left: -10px;
    }

    .nav-wrapper-bg{
        display: none;
    }

    .transparent-bg{
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.15;
        border-radius: 69px;
    }

    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }

    .img-left {
        margin-bottom: 30px;
        max-width: 100%;
    }

    .image-wrapper{
        left: 65px;
    }

    .faq-section-wrapper{
        padding-bottom: 30px;
    }
    
}

/* Read more */

    .m-slide-down {
        height: 0;
        overflow: hidden;
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
    }
    .m-hidden, .mobile-detector {
        display: none;
    }
    .m-visible {
        display: initial;
    }
    .m-hidden {
        display: initial;
    }
    .m-slide-down-measure p {
        margin: 0!important;
        padding-top: 20px;
    }
    .m-readmore-btn{
        background: transparent;
        border: none;
        text-transform: uppercase;
        font-size: 0;
        font-weight: 500;
        color: white;
        width: 100%;
        margin: 15px auto 50px;
    }

    .m-readmore-btn::before {
        content: "";
        cursor: pointer;
        display: inline-block;
        width: 17px;
        height: 17px;
        vertical-align: top;
        margin-top: 2px;
    }
    .m-readmore-btn.read-more-2::before {
        background: url("../image/more.png") no-repeat 0 0/contain;
    }
    .m-readmore-btn:hover.read-more-2::before{
        background: url("../image/more-hover.png");
    }
    .m-readmore-btn.collapse-2::before{
        background: url("../image/less.png") no-repeat 0 0/contain;
    }
    .m-readmore-btn:hover.collapse-2::before {
        background: url("../image/less-hover.png") no-repeat 0 0/contain;
    }
    .m-readmore-btn * {
        pointer-events: none;
    }
    .m-visible {
        display: initial;
    }
    .m-hidden, .mobile-detector {
        display: none;
    }